<template>
	<div>
		<v-select
			v-model="selected"
			:options="optionsForCountries"
			label="text"
			:reduce="(option) => option.value"
			:clearable="false"
			@option:selected="handleCountryChange"
			:class="{ 'is-invalid': error }"
		/>
		<div v-if="error" class="invalid-feedback">
			{{ FormMSG(2, 'Country is required') }}
		</div>
	</div>
</template>

<script>
import { getObjectFromValue } from '@/shared/utils';
import languageMessages from '@/mixins/languageMessages';
import { isNil, noTilde } from '@/shared/utils';

export default {
	name: 'CountrySelectorComponent',
	mixins: [languageMessages],
	props: {
		customId: {
			type: String,
			required: false,
			default: 'country-selector'
		},
		countrySelected: {
			type: Number,
			required: false,
			default: null
		},
		error: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		/**
		 * @return {Array}
		 */
		optionsForCountries() {
			let options = this.FormMenu(8);
			options.sort((a, b) => {
				if (noTilde(a.text) < noTilde(b.text)) {
					return -1;
				}
				if (noTilde(a.text) > noTilde(b.text)) {
					return 1;
				}

				return 0;
			});

			options = [
				{
					value: 0,
					text: this.FormMSG(1, 'Select a country ...')
				},
				...options
			];

			return options;
		},

		/**
		 * @return {Array}
		 */
		optionsForCountryCode() {
			return this.FormMenu(9);
		}
	},
	data() {
		return {
			selected: 16965
		};
	},
	mounted() {
		this.selected = +this.countrySelected === 0 ? null : this.countrySelected;
	},
	methods: {
		/**
		 * @param {Number} countryKey
		 */
		handleCountryChange(countryKey) {
			const countryCode = getObjectFromValue(this.optionsForCountryCode, 'value', countryKey);
			const countryName = getObjectFromValue(this.optionsForCountries, 'value', countryKey);
			this.$emit('change', {
				key: countryKey,
				code: countryCode ? countryCode.text : 'BE',
				name: countryName ? countryName.text : ''
			});
		}
	},
	watch: {
		countrySelected: {
			handler(value) {
				if (!isNil(value) && !isNaN(value)) {
					this.selected = value;
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
