var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-select", {
        class: { "is-invalid": _vm.error },
        attrs: {
          options: _vm.optionsForCountries,
          label: "text",
          reduce: (option) => option.value,
          clearable: false,
        },
        on: { "option:selected": _vm.handleCountryChange },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _vm.error
        ? _c("div", { staticClass: "invalid-feedback" }, [
            _vm._v(
              "\n\t\t" + _vm._s(_vm.FormMSG(2, "Country is required")) + "\n\t"
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }